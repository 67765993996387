/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { isAuthenticated, logout, login } from "../utils/auth"
import { Helmet } from "react-helmet"
import Lang from "./Lang"
import "../styles/main.scss"
import { ToastProvider } from 'react-toast-notifications';
import Button from "../components/Button"
import { navigateQuery } from "../components/QueryLink"

export default ({ children }) => {

	function swithLang() {
		let lang = localStorage.lang === 'en' ? 'fr' : 'en'
		navigateQuery({ field: "lang", value: lang })
	}

	return <ToastProvider styles={{
		container: (provided) => ({ ...provided, zIndex: 1000 })
	  }}>
			<Helmet>
				<title>My Private Fund</title>
			</Helmet>
			<header className="nav">
				<div>
					<Link to="/" className="logo-section">
						<img className="logo" src="/img/logo.svg" alt="heart logo" />
						<span className="site-title">My Private Fund</span>
					</Link>
				</div>
				<div className="btnsLink">
					<Button mr auto onClick={swithLang}><Lang en="Français" fr="English" /></Button>
					{!isAuthenticated() && <Button auto onClick={login}><Lang en="Login" fr="Connexion" /></Button>}
					{isAuthenticated() && <Button auto onClick={logout}><Lang en="Logout" fr="Déconnexion" /></Button>}
				</div>
			</header>
			<>{children}</>
			<footer className="footer">
				<div className="footer-container">
					<div>
						<img className="logo" src="/img/logo.svg" alt="heart logo" /> <br/>
						<small>&copy; {new Date().getFullYear()} My Private Fund. All rights reserved.</small>
					</div>

					<div className="footer-links">
						<Link to="/"><Lang en="Home" fr="Accueil" /></Link>
						<a href="https://fundmyprivatecampaign.freshdesk.com/support/solutions" target="_blank" rel="noopener noreferrer">FAQ</a>
						<a href="https://fundmyprivatecampaign.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer">Support</a>
						{!isAuthenticated() && <a href="#" onClick={login}><Lang en="Login" fr="Connexion" /></a>}
						{isAuthenticated() && <a href="#" onClick={logout}><Lang en="Login" fr="Connexion" /></a>}
						<a href="#" onClick={swithLang}><Lang en="Français" fr="English" /></a>

					</div>
				</div>
			
			</footer>
	</ToastProvider>
}