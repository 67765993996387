import { getTokenSilently } from "./auth"
import axios from "axios"

export const setPageInfo = async function (action) {
    const token = getTokenSilently()

    const { data } = await axios.get(
        `/.netlify/functions/${action}`,
        {
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })

    if (data) {
        this.setState(data)
    }
}

export const postInfo = async function (action, body) {
    const token = getTokenSilently()

    const { data } = await axios.post(
        `/.netlify/functions/${action}`,
        body,
        {
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })

    return data
}


		