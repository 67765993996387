import React, { useState } from 'react'
import axios from 'axios'
import Button from "../Button"
import s from "./styles.module.scss"
import { useToasts } from 'react-toast-notifications'
import { getTokenSilently } from "../../utils/auth"

export const Field = function({ type, name, label, noGroup, dollarSign, ...props }){

    if (type === "checkbox") {
        return <div className={!noGroup && s.formGroup}>
            <input className={s.input} type="checkbox" name={name} id={name} {...props} />
            {label && <label className={s.label} htmlFor={name}>{label}</label>}
        </div>
    }

    return <div className={!noGroup && s.formGroup}>
        {label && <label className={s.label}>{label}</label>}
        {dollarSign && <div className={s.dollar}>$</div>}
        {type !== "textarea" && <input className={s.input} type={type} name={name} {...props} />}
        {type === "textarea" && <textarea className={s.input} type={type} name={name} {...props} />}
    </div>
}

const fType = <Field />

export const Form = function({ children, action, onSubmit, submit, error, toast }) {
    const [values, update] = useState({})
    const [loading, setLoading] = useState(false)
    const { addToast } = useToasts();

    async function submitted(e) {
        e.preventDefault()
        setLoading(true)

        const token = getTokenSilently()

        try {
            if (action) {
                console.log("VALUES", values)
                const response = await axios.post(`/.netlify/functions/${action}`, values, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                await onSubmit(response.data)
            } else {
                await onSubmit(values)
            }

            if (toast) {
                addToast(toast.text, {
                    appearance: toast.type || 'success',
                    autoDismiss: true
                })
            }

            childrens.forEach((child) => {
                if (child.type.displayName === fType.type.displayName) {
                    if (child.props.type === "checkbox") values[child.props.name] = false
                    values[child.props.name] = child.props.defaultValue || ""
                }
            })
            
    
            setLoading(false)
        } catch(err) {
            console.error(err)
            setLoading(false)

            addToast(error, {
                appearance: 'error',
                autoDismiss: true,
                timeout: 10000
            })

        }
    }

    const childrens = children.length ? children: [children]

    childrens.forEach((child) => {
        if (!values[child.props.name] && child.type.displayName === fType.type.displayName) {
            if (child.props.type === "checkbox") values[child.props.name] = false
            else values[child.props.name] = child.props.defaultValue || ""
        }
    })

    return <form onSubmit={submitted}>
        {childrens.map((Child, i) => {
            if (Child.type.displayName !== fType.type.displayName || !Child.props.type) return Child
            
            if (Child.props.type === "checkbox") {
                return <Field key={i} onChange={(e) => {update({...values, [Child.props.name]: !values[Child.props.name] })}} checked={values[Child.props.name]} {...Child.props} />
            }

            return <Field key={i} onChange={(e) => { update({...values, [Child.props.name]: e.target.value })}} value={values[Child.props.name]} {...Child.props} />
        })}

        <Button loading={loading} disabled={loading} type="submit">{submit}</Button>
    </form>
}