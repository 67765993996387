import styled from 'styled-components'

export const Box = styled.div`
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.16);
    border-radius: 4px;
    padding: 36px;
    margin-bottom: 20px;
`

export const Container = styled.div`
    width: 92%;
    margin: 0 auto;
    max-width: 1200px;
`

export const Split = styled.div`
    display: flex;
    align-items: ${props => props.align ? props.align : "flex-start"};
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -20px;

    @media screen and (max-width: 768px) {
        display: block;
        margin-right: 0;
    }
`

export const SplitChild = styled.div`
    width: calc(${props => props.grow ? `${props.grow * 10}%` : "50%"} - 20px);
    margin-right: 20px;
    text-align: ${props => props.right ? `right` : "inherit"};

    @media screen and (max-width: 768px) {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
    }
`

export const SplitChildSmall = styled.div`
    width: calc(${props => props.grow ? `${props.grow * 10}%` : "50%"} - 10px);
    margin-right: 10px;
    text-align: ${props => props.right ? `right` : "inherit"};

    @media screen and (max-width: 768px) {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
    }
`

export const Help = styled.div`
    text-align: center;
    margin: 15px 0 5px;
    font-size: small;
`

export const AdminTitle = styled.h3`
    margin: 0 0 5px;
`

export const Focus = styled.div`
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
`

export const FocusTitle = styled.h3`
    margin: 0 0 10px;
`
export const ProfileSection1 = styled.h3`
    margin: 0;
`

export const ProfileSection2 = styled.h3`
    margin: 0 0 0 0;
`