import React from "react"

import queryString from "query-string"
import { navigate } from "@reach/router"

export const queryLink = function({ field, value, remove, query }) {
    const parsed = queryString.parse(query || (typeof window == "object" && window.location.search))

    if (remove) {
        delete parsed[remove]
    } else {
        parsed[field] = value
    }

    return queryString.stringify(parsed);
}

export const navigateQuery = function({ field, value, remove }) {
    navigate(`?${queryLink({ field, value, remove })}`)
}

export default function({ children, field, value, remove }) {
    if (typeof window == "object" && window.location) {
        const stringified = queryLink({ field, value, remove })

        function navTo(e) {
            e.preventDefault()
            navigate(`?${stringified}`)
        }

        return <a href={`?${stringified}`} onClick={navTo}>{children}</a>
    }

    return children
}