import React from "react"
import queryString from "query-string"

export const t = ({ fr, en }) => {
    let lang = 'en'

    if (typeof window == "object" && queryString.parse(window.location.search).lang) {
        const parsed = queryString.parse(window.location.search);
        if (parsed.lang) {
            lang = parsed.lang
            localStorage.lang = parsed.lang
        }
    } else if (typeof window == "object" && localStorage.lang) {
        lang = localStorage.lang
    }

    return lang === 'en' ? en : fr
}

export default (langs) => {
    return <>
        {t(langs)}
    </>
}